import React, { useRef, useState } from 'react';
import classes from "./Accordian.module.css";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";

export default function Accordian({ title, children }) {
    const [open, setOpen] = useState(false);
    const contentRef = useRef(null);

    return (
        <div className={open===true ? classes.openedClass :  classes.accordian}>
            <p className={`f-19 fw-600 ${classes.title}`}>{title} <span className={open===true ? classes.opened : classes.icon} onClick={() => setOpen(!open)}>{open===true ? <RiArrowUpSLine size={23}/> : <RiArrowDownSLine size={23}/>}</span></p>
            <div className={classes.contentDiv} ref={contentRef} style={{maxHeight: open ? `${contentRef?.current?.scrollHeight}px` : "0px"}}>{children}</div>
        </div>
    )
};
        