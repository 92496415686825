import React from 'react';
import classes from "./ServiceCard.module.css";
import { GoArrowRight } from "react-icons/go";

export default function ServiceCard({data}) {
  return (
    <div className={classes.mainDiv}>
      <div className={classes.imageDiv}><img src={data?.image} alt="img" /></div>
      <p className={`f-26 fw-600 ${classes.title}`}>{data?.title}</p>
      <p className={`f-15 fw-400`}>{data?.content}</p>
      <p className="f-17 fw-400">Read More <GoArrowRight size={20} /></p>
    </div>
  )
}
