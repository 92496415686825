import React from 'react';
import classes from "./TribalSupport.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import { Timeline } from 'primereact/timeline';
import { RxDotFilled } from "react-icons/rx";
import Button from "../../../components/Button";
import { TribalSupportCardData } from '../../../data/app-data';
import TribalSupportCard from '../../../components/TribalSupportCard';

export default function TribalSupport() {

  const timeLine = [
    {point: "TLE’s/Lenders"},
    {point: "Board/Committee members"},
    {point: "Tribal Regulatory Authority"},
  ];

  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <style jsx>
        {`
          .p-timeline-event-opposite{
            display: none;
          }
          .p-timeline-event{
            min-height: 40px;
          }
        `}
      </style>

    <Container>
      <Row className='py-5 gy-3'>
        <Col md={12} className={classes.colDiv}>
          <p className={`mt-2 f-45 fw-600`}>Expert <span className='highlightedText'>Tribal</span> Regulatory <span className='highlightedText'>Support</span></p>
          <p className={`f-16 fw-400 ${classes.detailClass}`}>Tribal Reg offers experienced tribal regulatory professionals to tribes and tribal entities on a fractional basis; 
          as little as 10 hours per month.</p>
        </Col>
        <Col lg={5} md={12} className='mt-5'>
          <p className="f-30 fw-600">Tribal <span className="highlightedText">Regulatory</span> Program</p>
          <p className="f-16 fw-400 my-2">The comprehensive program allows for Oversight and
          Reporting:</p>
          <Timeline value={timeLine} content={(item) => item?.point} marker={<RxDotFilled color='var(--orange-color)' />}/>

          <div className={classes.btnDiv}>
            <Button label={"Learn More"} variant={"primary"} className={classes.btnClass}/>
            <Button label={"contact us"} variant={"outlined"} onClick={()=> handleScroll("contact")}/>
          </div>
        </Col>
        <Col lg={7} md={12} className='mt-5'>
          <p className="f-16 fw-400">Our Fractional resources come fully equipped and ready to do any and all of the following, but not limited too:</p>

          <Row className='gy-3 mt-2'>
            {TribalSupportCardData?.map((e, i)=>{
              return(
                <Col key={i} xl={4} md={6} sm={12} className='mt-2'>
                <TribalSupportCard data={e} />
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
    </Container>
    </>
  )
}
