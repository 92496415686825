import React from 'react';
import classes from "./AboutSection.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import Image from "../../assets/images/about.png";
import Button from "../../components/Button";

export default function AboutSection() {
  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Container>
      <Row className={`gy-3 ${classes.rowClass}`}>
        <Col lg={6} md={12} sm={12}>
          {/* <div> */}
            <img src={Image} alt="img" className={classes.image} />
            {/* </div> */}
        </Col>
        <Col lg={6} md={12} sm={12}>  
          <p className={`f-30 fw-400`}>About Us</p>
          <p className={`mt-2 f-45 fw-600 ${classes.heading}`}>Trusted Regulatory <span className='highlightedText'>Support</span> for Tribal <span className='highlightedText'>Enterprises.</span></p>
          <p className={`f-16 fw-400 ${classes.aboutContent}`}>Tribal Regulatory Services (Tribal Reg) is a tribal regulatory services company for tribally owned consumer lending and e-commerce businesses. Tribal Reg offers experienced tribal regulatory professionals to tribes and tribal entities on a fractional basis; as little as 20 hours per month. Fractional resources primarily work remotely but can be onsite if the need arises. Our founders have deep experience with the tribal regulatory industry and tribal lending entities (TLE) through their over 20 tribal relationships across the country. We specialize in delivering a cost effective, professional, efficient, and valuable resource to tribal clients who want to ensure compliance in their programs.</p>

          <div className={classes.btnDiv}>
            <Button label={"learn more"} variant={"primary"} className={classes.btnClass} />
            <Button label={"contact us"} variant={"outlined"} onClick={() => handleScroll("contact")} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}
