import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import { FaLocationDot } from "react-icons/fa6";
import Trend from "../assets/images/trend.png";
import User from "../assets/images/user.png";
import Graph from "../assets/images/graph.png";
import Care from "../assets/images/care.png";

export const NavTabs = [
    {
        nav: "Home",
        path: "home"
    },
    {
        nav: "About Us",
        path: "about"
    },
    {
        nav: "Services",
        path: "services"
    },
    {
        nav: "Contact Us",
        path: "contact"
    },
];

export const AuditData = [
    {
        text: "Lender Compliance Audits",
        subText: {
            text: "Establish a complaint handling process"
        }
    },
    {
        text: "Regulatory Compliance GAP Review and Assessment"
    },
    {
        text: "Development of Standard Operating Procedures"
    },
    {
        text: `Testing Program to Ensure Lenders Operate within Regulatory Guidelines`
    },
    {
        text: "Identification of Barriers to Compliance"
    },
    {
        text: `Advise on Best Practices and Current Areas of Scrutiny of Regulators`
    },
    {
        text: "Remote or On-site Audits"
    },
    {
        text: "Audit Lenders and High-risk Vendors"
    },
];

export const AccordianData = [
    {
        title: "How does the fractional service model work?",
        data: "Our fractional service model allows clients to access experienced regulatory professionals for as few as 20 hours per month, offering flexible and cost-effective solutions."
    },
    {
        title: "What industries does Tribal Reg specialize in?",
        data: ""
    },
    {
        title: "What is the experience level of your team?",
        data: ""
    },
    {
        title: "Are your services available remotely?",
        data: ""
    },
    {
        title: "How can Tribal Reg help with compliance?",
        data: ""
    },
    {
        title: "How can Tribal Reg help with compliance?",
        data: ""
    },
    {
        title: "Can Tribal Reg assist with board and committee needs?",
        data: ""
    },
]

export const ServiceCardData = [
    {
        image: Trend,
        title: "Quality Regulatory Compliance...",
        content: `A Tribal Reg Compliance Professional is a key business partner for each tribal regulatory body engaged in 
        e-commerce.`
    },
    {
        image: User,
        title: `Board/Committee Contribution`,
        content: `Tribal Reg can perform a number of functions for the board that regulates your business. This would include`
    },
    {
        image: Graph,
        title: "Program Implementation",
        content: `Tribal Reg can assist in implementation of various businesses including consumer/commercial lending.`
    },
    {
        image: Care,
        title: "Regulatory Training & Support",
        content: `This service can focus on providing education and resources to ensure clients understand and adhere to regulatory requirements,`
    },
]

export const ComprehensiveData = [
    {
        point: "Board membership"
    },
    {
        point: "Board Training"
    },
    {
        point: "New lending program implementation"
    },
    {
        point: "Create a Licensing process"
    },
    {
        point: "Review of results of audits/regulatory reviews"
    },
    {
        point: `Perform audits or Assist in selection of auditors and
oversight of audit process`
    },
    {
        point: "Development of a compliance program"
    },
];

export const ComprehensiveData2 = [
    {
        point: "Establish a complaint handling process"
    },
    {
        point: "Establish compliance monitoring of the licensees"
    },
    {
        point: "Establish a dispute resolution process"
    },
];

export const BusinessSupportData = [
    {
        list: "Board membership"
    },
    {
        list: "Vendor selection"
    },
    {
        list: "Ensuring all policies and procedures are in place "
    },
    {
        list: "Ensuring all consumer documents are in place"
    },
    {
        list: "Reviewing product testing process"
    },
    {
        list: "Ensuring all sign-offs are obtained"
    },
    {
        list: `Reviewing program results from a compliance
        perspective`
    },
    {
        list: `Making recommendations to enhance program
performance`
    },
];

export const TribalSupportCardData = [
    {
        count: "1",
        text: `Tribal lending and ecommerce policies and procedures`
    },
    {
        count: "2",
        text: `Lending Program implementation`
    },
    {
        count: "3",
        text: `Regulatory,Compliace, or Audit Committee Member`
    },
    {
        count: "4",
        text: `Lending & E-commerce Compliance Oversight & Training`
    },
    {
        count: "5",
        text: `Lender, Vendor and Individual Licensing`
    },
    {
        count: "6",
        text: `Regulatory Training`
    },
];

export const TrainingSectionData = [
    {
        point: `On-line Training and Testing designed to Meet the Requirements of Regulators`
    },
    {
        point: `Training Meets the Needs of the Full Lending Organization`
    },
    {
        point: `Training is Comprehensive and Covers all Regulatory Training`
    },
    {
        point: `Courses are Updated as Regulations Change`
    },
    {
        point: `Improved Knowledge Retention`
    },
    {
        point: `Consistency in the Quality of Training`
    },
    {
        point: `Integrated Training Topics to Address Your Core Business Strategy`
    },
    {
        point: `Mitigate the Risks of Knowledge Deficiencies`
    },
];

export const FooterIcons = [
    {
        icon: <FaFacebookF/>
    },
    {
        icon: <FaInstagram/>
    },
    {
        icon: <FaLinkedinIn/>
    },
];

export const Services = {
    title: "Our Services",
    links: [
        {
            link: 'Executive Experience'
        },
        {
            link: 'Policy and Process'
        },
        {
            link: 'Accounting'
        },
        {
            link: 'Strategic Consulting'
        },
        {
            link: 'Management Consulting'
        },
    ]
};

export const QuickLinks = {
    title: "QuickLinks",
    links: [
        {
            link: 'Home',
            path: "home",
        },
        {
            link: 'Services',
            path: "services",
        },
        {
            link: 'About us',
            path: "about",
        },
        {
            link: 'Contact us',
            path: 'contact',
        },
    ]
};

export const InfoData = [
    {
        icon: <FaPhoneAlt/>,
        title: "Any Questions?",
        content: "(877) 212-6380 X 1002",
    },
    {
        icon: <TfiEmail/>,
        title: "Write Email?",
        content: "info@tribalreg.com"
    },
    {
        icon: <FaLocationDot/>,
        title: "Location",
        content: `1234 Maple Street Miami, FL 33101`,
    },
];

