import React from 'react';
import classes from "./TrainingSection.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import { TrainingSectionData } from '../../data/app-data';
import { BsDot } from 'react-icons/bs';
import Button from "../../components/Button";
import Image from "../../assets/images/training.png";

export default function TrainingSection() {

  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <Row className='py-5 gy-4'>
        <Col lg={6} md={12}>
        <p className={`f-30 fw-400`}>Training</p>
        <p className={`mt-2 f-45 fw-600`}>Consistent and <span className='highlightedText'>High-Quality</span> Training </p>

        <ul className='mt-3'>
          {TrainingSectionData?.map((e, i)=>{
            return(
              <li className={`${classes.list} my-1`} key={i}> {e?.point}</li>
              // <p className={`${classes.list} my-1`} key={i}><BsDot size={25}/> {e?.point}</p>
            )
          })}
          </ul>

          <div className={classes.btnDiv}>
            <Button label={"learn more"} variant={"primary"} className={classes.btnClass}/>
            <Button label={"contact us"} variant={"outlined"} onClick={()=> handleScroll("contact")} />
          </div>
        </Col>
        <Col lg={6} md={12} className='text-center'>
            <img src={Image} alt="img" className={classes.image} />
        </Col>
      </Row>
    </Container>
  )
}
