import React from 'react';
import classes from "./BusinessSupport.module.css";
import Image from "../../../assets/images/business-support.png"
import { Col, Container, Row } from 'react-bootstrap';
import { BusinessSupportData } from '../../../data/app-data';
import Button from '../../../components/Button';

export default function BusinessSupport() {
  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Container>
      <Row className='py-5'>
        <Col lg={6} md={12}>
            <img src={Image} alt="img" className={classes.image} />
        </Col>
        <Col lg={6} md={12} className='my-auto'>
        <p className={`mt-5 f-45 fw-600`}>Business <span className='highlightedText'>Implementation</span> Support</p>
        <p className="f-16 f-400 mt-3">Tribal Reg can assist in the implementation of various new businesses including consumer/commercial lending.</p>
        <p className="f-16 fw-600 mt-4">We assist in all phases including:</p>

        <ul className="mt-3">
          {BusinessSupportData?.map((e, i)=>{
            return(
              <li className={classes.list} key={i}>{e?.list}</li>
            )
          })}
        </ul>

        <div className={classes.btnDiv}>
            <Button label={"learn more"} variant={"primary"} className={classes.btnClass}/>
            <Button label={"contact us"} variant={"outlined"} onClick={()=> handleScroll("contact")}  />
          </div>
        </Col>
      </Row>
    </Container>
  )
}
