import React from 'react';
import classes from "./HomePage.module.css";
import { Container } from 'react-bootstrap';
import BannerSection from '../../sections/BannerSection';
import Footer from '../../components/Footer';
import AboutSection from '../../sections/AboutSection';
import Bg from "../../assets/images/ring.png";
import ServicesSection from '../../sections/ServicesSection';
import LeadTeamSection from '../../sections/LeadTeamSection';
import ComprehensiveSupport from '../../sections/SupportSection/ComprehensiveSupport';
import BusinessSupport from '../../sections/SupportSection/BusinessSupport';
import TribalSupport from '../../sections/SupportSection/TribalSupport';
import TrainingSection from '../../sections/TrainingSection';
import AuditSection from '../../sections/AuditSection';
import FAQsSection from '../../sections/FAQsSection';
import ContactSection from '../../sections/ContactSection';
import { ToastContainer } from 'react-toastify';

export default function HomePage() {
  return (
    <Container fluid className='p-0'>
      <ToastContainer/>
      <div className={classes.bannerDiv} id='home'>
        <BannerSection />
      </div>
      <div className={classes.aboutDiv} id='about'>
        <img src={Bg} alt="img" className={classes.bg} />
        <AboutSection/>
        </div>
        <div className={classes.servicesDiv} id='services'>
          <ServicesSection/>
        </div>
        <div className={classes.leadTeamDiv} id="team">
          <LeadTeamSection/>
        </div>
        <div className={classes.comprehensiveSupportDiv}>
          <ComprehensiveSupport/>
        </div>
        <div className={classes.businessSupportDiv}>
          <BusinessSupport/>
        </div>
        <TribalSupport/>
        <div className={classes.trainingDiv}>
          <TrainingSection/>
        </div>
        <div className={classes.blackDiv}>
          <AuditSection/>
        </div>
        <FAQsSection/>
        <div className={classes.contactDiv} id='contact'>
        <ContactSection/>
        </div>
      <div className={classes.footerDiv}>
        <Footer />
      </div>
    </Container>
  )
}
