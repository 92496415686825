import React from 'react';
import classes from "./ServicesSection.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import { ServiceCardData } from '../../data/app-data';
import ServiceCard from '../../components/ServiceCard';

export default function ServicesSection() {
  return (
    <Container>
      <Row className='gy-4'>
        <Col md={12}>
        <p className={`f-30 fw-400`}>Our Services</p>
          <p className={`mt-2 f-45 fw-600 ${classes.heading}`}>Tribal <span className='highlightedText'>Regulations</span> Services</p>
          <p className={`f-16 fw-400 mt-3 ${classes.content}`}>Tribal Reg. is a tribal regulatory services company for tribally owned consumer online lending and e-commerce businesses.
          Tribal Reg. offers experienced tribal regulatory professionals to tribes and tribal entities on a fractional basis for as little as 10 hours per month. Fractional resources primarily work remotely but can be onsite if the need arises. We specialize in delivering a cost effective, professional, efficient, and valuable resource to tribal clients who want to ensure compliance in their programs.</p>
        </Col>
      </Row>
      <Row className='mt-5 gy-3'>
      {ServiceCardData?.map((e, i)=>{
          return(
            <Col xl={3} md={6} sm={6} xs={12} key={i}><ServiceCard data={e} /></Col>
          )
        })}
      </Row>
    </Container>
  )
}
