import React from 'react';
import classes from "./InfoBox.module.css";
import { InfoData } from '../../data/app-data';
import InfoComponent from '../InfoComponent';

export default function InfoBox() {
  return (
    <div className={classes.mainDiv}>
      <p className={`f-40 fw-600 ${classes.heading}`}>Info</p>
      {InfoData?.map((e, i)=>{
        return(
            <InfoComponent data={e} key={i} showIcon showTitle />
        )
      })}
    </div>
  )
}
