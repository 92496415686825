import React from 'react';
import classes from "./LeadTeamSection.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import Image from "../../assets/images/lead-team.png";
import Button from "../../components/Button";

export default function LeadTeamSection() {
  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Container>
      <Row className='gy-3 pt-5'>
        <Col lg={5} md={12} className='text-center'>
            <img src={Image} alt="img"  className={classes.image} />
        </Col>
        <Col lg={7} md={12}className='my-auto py-5'>
        <p className={`f-30 fw-400`}>Leadership Team</p>
          <p className={`mt-2 f-45 fw-600 ${classes.heading}`}>A <span className='highlightedText'>Compliance</span> Leader with Decades of <span className='highlightedText'>Expertise</span></p>
          <p className={`f-16 fw-400 ${classes.content}`}>{`Bret Crandall, Director of Compliance: Is a veteran expert with over 30 years of experience in the Collection Industry to include compliance, vendor management, licensing, business planning, and operations. Bret is accustomed to the rigors of fast-paced, customer-driven environments requiring a sharp attention to detail, consummate accuracy, and an array of technical skills. Bret possesses a combination of strategic team leadership and effective problem-solving skills that aids our clients with Compliance Oversight, Complaint Management, Licensing, Audits, Training and CFPB readiness.`}</p>

          <div className={classes.btnDiv}>
            <Button label={"learn more"} variant={"primary"} className={classes.btnClass} />
            <Button label={"contact us"} variant={"outlined"} onClick={()=> handleScroll("contact")}/>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
