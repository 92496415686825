import React, { useState } from 'react';
import classes from "./Header.module.css";
import { NavTabs } from '../../data/app-data';
import Logo from "../../assets/images/logo.png";
import Button from '../Button';
import { FaBars } from "react-icons/fa";
import Drawer from 'react-modern-drawer';
import { RxCross2 } from "react-icons/rx";

export default function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <style>
        {`
      .EZDrawer .EZDrawer__container{
        background: none;
      }
      `}
      </style>

      <div className={classes.mainDiv}>
        <div className={classes.imageDiv}>
          <img src={Logo} alt="img" />
        </div>
        <div className={classes.tabsDiv}>
          {NavTabs?.map((e, i) => (
            <p key={i} className={`${classes.tabs} f-17 fw-500`} onClick={() => handleScroll(e?.path)}>{e?.nav} <span>{e?.icon}</span></p>
          ))}
        </div>
        <Button label={"contact us"} variant={"primary"} className={classes.btn} onClick={() => handleScroll("contact")} />
        <div className={classes.hamburger} onClick={toggleDrawer}>
          <FaBars color='var(--white-color)' size={25} />
        </div>

        <Drawer
          open={isDrawerOpen}
          onClose={toggleDrawer}
          direction="left"
          className={classes.drawer}
        >
          <div className={classes.drawerContent}>
            <div className={classes.closeIcon} onClick={toggleDrawer}>
              <RxCross2 color='var(--white-color)' size={25} />
            </div>
            {NavTabs?.map((e, i) => (
              <p
                key={i}
                className={`${classes.tabs} my-3 f-17 fw-500`}
                onClick={() => handleScroll(e?.path)}
              >
                {e?.nav} <span>{e?.icon}</span>
              </p>
            ))}
            <Button label={"Contact Us"} className={"mt-5"} variant={"primary"} onClick={() => handleScroll("contact")} />
          </div>
        </Drawer>
      </div>
    </>
  )
}
