import React from 'react';
import classes from "./InfoComponent.module.css";

export default function InfoComponent({data, className, showTitle, spanClass, showIcon, contentClass}) { 
    return (
        <div className={`${classes.infoDiv} ${className}`}>
            {showIcon && (
                <div>
                <span className={`${classes.icon} ${spanClass}`}>{data?.icon}</span>
            </div>
            )}
            <div>
                {showTitle && (<p className={`f-16 fw-600`}>{data?.title}</p>)}
                <p className={`f-14 fw-300 ${contentClass}`}>{data?.content}</p>
            </div>
        </div>
    )
}
