import React from 'react';
import classes from "./FAQsSection.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import { AccordianData } from '../../data/app-data';
import Accordian from "../../components/Accordian";
import Button from "../../components/Button";

export default function FAQsSection() {

  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <Row className='pt-5 pb-3 gy-3'>
        <Col lg={6} md={8}>
        <p className={`mt-2 f-45 fw-600 ${classes.heading}`}><span className='highlightedText'>Frequently</span> Asked Questions</p>
        <p className="f-16 fw-400">Tribal Reg provides flexible regulatory services for tribally owned businesses, ensuring compliance with cost-effective solutions and expert support.</p>
        </Col>
        <Col lg={6} md={4} className={`${classes.button} my-auto`}>
          <Button label={"contact us"} variant={"primary"} onClick={()=> handleScroll("contact")} />
        </Col>
      </Row>
      <Row className='py-5 gy-3'>
        {AccordianData?.map((e,i)=>{
          return(
            <Col md={6} key={i} className='my-2'>
              <Accordian title={e?.title}>
                <p className="f-14 fw-400">{e?.data}</p>
              </Accordian>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}
