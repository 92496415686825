import React, { useRef } from 'react';
import classes from "./Footer.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import Logo from "../../assets/images/logo.png";
import { FooterIcons, InfoData, QuickLinks, Services } from '../../data/app-data';
import InfoComponent from '../../components/InfoComponent';
import { Panel } from 'primereact/panel';
import { IoIosArrowDropup, IoIosArrowDropdown } from "react-icons/io";

export default function Footer() {
    const services = Services;
    const quickLinks = QuickLinks;

    const servicesRef = useRef(null);
    const quickLinkRef = useRef(null);
    const contactRef = useRef(null);

    const Title = ({ title }) => {
        return (
            <div className={classes.titleDiv}>
                <p className={`f-22 fw-600 ${classes.title}`}>{title}</p>
            </div>
        )
    };

    const handleScroll = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <style jsx>
                {`
                .p-panel-icons{
                    color: #fff;
                }
                .p-panel-header {
                    background-color: transparent !important; 
                    color: var(--white-color) !important; 
                    padding: 0 !important; 
                    border: none;
                }

                .p-panel-content {
                    background-color: transparent !important;
                    color: var(--white-color) !important; 
                    border: none;
                }

                .p-panel-icons {
                    color: var(--white-color) !important; 
                }
                .p-panel-header-icon:enabled:hover{
                    background: none;
                }
            `}
            </style>
            <Container>
                <Row className='gy-4 pb-4'>
                    <Col lg={5} md={12}>
                        <div className={classes.footerDiv}>
                            <img src={Logo} alt="img" className={classes.image} />
                            <p className="f-16 fw-400 my-4">{`We offer expert regulatory services for tribally owned lending and e-commerce businesses, providing flexible support to ensure compliance and success.`}</p>
                            <div className={classes.socialDiv}>
                                {FooterIcons?.map((e, i) => {
                                    return (
                                        <span key={i} className={classes.spanClass}>{e?.icon}</span>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={classes.responsiveFooter}>
                            <div className={classes.footerImage}>
                                <img src={Logo} alt="img" className={classes.image} />
                                <div className={classes.socialDiv}>
                                    {FooterIcons?.map((e, i) => {
                                        return (
                                            <span key={i} className={classes.spanClass}>{e?.icon}</span>
                                        )
                                    })}
                                </div>
                            </div>
                            <p className="f-16 fw-400 my-4">{`We offer expert regulatory services for tribally owned lending and e-commerce businesses, providing flexible support to ensure compliance and success.`}</p>
                        </div>
                    </Col>
                    <Col lg={2} md={4} sm={12}>
                        <div className={classes.serviceClassDiv}>
                            <Title title={services?.title} />
                            {services?.links?.map((e, i) => {
                                return (
                                    <p className={`f-14 f-500 ${classes.linkClass}`} key={i}>{e?.link}</p>
                                )
                            })}
                        </div>
                        <Panel ref={servicesRef} header={<Title title={services?.title} />} toggleable className={classes.serviceClassPanel} expandIcon={<IoIosArrowDropdown color='var(--white-color)' size={20} />} collapseIcon={<IoIosArrowDropup color='var(--white-color)' size={20} />}>
                            {services?.links?.map((e, i) => {
                                return (
                                    <p className={`f-14 f-300 ${classes.linkClass}`} key={i}>{e?.link}</p>
                                )
                            })}
                        </Panel>
                    </Col>
                    <Col lg={2} md={4} sm={12}>
                        <div className={classes.quickClassDiv}>
                            <Title title={quickLinks?.title} />
                            {quickLinks?.links?.map((e, i) => {
                                return (
                                    <p className={`f-14 f-300 ${classes.linkClass} ${classes.quickClass}`} key={i} onClick={() => handleScroll(e?.path)}>{e?.link}</p>
                                )
                            })}
                        </div>
                        <Panel ref={quickLinkRef} header={<Title title={quickLinks?.title} />} className={classes.quickClassPanel} toggleable expandIcon={<IoIosArrowDropdown color='var(--white-color)' size={20} />} collapseIcon={<IoIosArrowDropup color='var(--white-color)' size={20} />}>
                            {quickLinks?.links?.map((e, i) => {
                                return (
                                    <p className={`f-14 f-300 ${classes.linkClass} ${classes.quickClass}`} key={i} onClick={() => handleScroll(e?.path)}>{e?.link}</p>
                                )
                            })}
                        </Panel>
                    </Col>
                    <Col lg={2} md={4} sm={12}>
                        <div className={classes.infoClassDiv}>
                            <Title title={"Contact Us"} />
                            {InfoData?.map((e, i) => {
                                return (
                                    <InfoComponent spanClass={classes.spanClass} data={e} key={i} showTitle={false} />
                                )
                            })}
                        </div>
                        <Panel ref={contactRef} header={<Title title={"Contact Us"} />} className={classes.infoClassPanel} toggleable expandIcon={<IoIosArrowDropdown color='var(--white-color)' size={20} />} collapseIcon={<IoIosArrowDropup color='var(--white-color)' size={20} />}>
                            {InfoData?.map((e, i) => {
                                return (
                                    <InfoComponent spanClass={classes.spanClass} data={e} key={i} showTitle={false} />
                                )
                            })}
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={classes.copyrightFooter}>
                        <p className="f-14 fw-300">Tribal Reg 2025 © All rights reserved</p>
                        <p className="f-14 fw-300">Powered By Shispare</p>
                        <div className={classes.footerLink}>
                            <p className="f-14 fw-300">Terms & Conditions</p>
                            <p className="f-14 fw-300">Privacy Policy</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
