import React from 'react';
import classes from "./AuditSection.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import { AuditData } from "../../data/app-data";
import { BsDot } from 'react-icons/bs';
import Image from "../../assets/images/audit.png";

export default function AuditSection() {
  return (
    <Container>
      <Row className='py-5 gy-3'>
        <Col lg={5} md={12} className='my-auto'>
          <div className={classes.imageDiv}>
            <img src={Image} alt="img" className={classes.image} />
          </div>
        </Col>
        <Col lg={7} md={12}>
          <p className={`f-30 fw-400`}>Audit</p>
          <p className={`mt-2 f-45 fw-600 ${classes.heading}`}>Streamlined <span className='highlightedText'>Audits</span> for <span className='highlightedText'>Compliance</span> Excellence </p>
          <p className="f-16 fw-400">Tribal Reg can help in the ever-changing regulations that impact your operations, advertising, policies, and much more:</p>

          <ul className='mt-3'>
            {AuditData?.map((e, i) => {
              return (
                <>
                  <li className={`f-16-fw-400 ${classes.point}`}>{e?.text}</li>
                  {e?.subText && (<p className={`f-15 fw-300 ${classes.point}`}>{`- ${e?.subText?.text}`}</p>)}
                </>
              )
            })}

          </ul>

          {/* {AuditData?.map((e, i)=>{
            return(
              <>
              <p className={`f-16-fw-400 ${classes.point}`}><BsDot size={25} /> {e?.text}</p>
              {e?.subText && (<p className={`f-15 fw-300 ${classes.point} ${classes.subList}`}>{`- ${e?.subText?.text}`}</p>)}
              </>
            )
          })} */}
        </Col>
      </Row>
    </Container>
  )
}
