import React from 'react';
import classes from "./TribalSupportCard.module.css";

export default function TribalSupportCard({data}) {
  return (
    <div className={classes.mainDiv}>
      <div className={classes.countDiv}>
        <p className="f-16 fw-600">{data?.count}</p>
      </div>
      <div className={classes.textDiv}>
        <p className="f-12 fw-400">{data?.text}</p>
      </div>
    </div>
  )
}
