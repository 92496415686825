import React from 'react';
import classes from "./ContactSection.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import InfoBox from '../../components/InfoBox';
import { toast } from 'react-toastify';
import { api } from '../../api/api';

export default function ContactSection() {

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const payload = {
      to: "no-reply@tribalreg.com",
      email: data?.email,
      from: "no-reply@tribalreg.com",
      subject: "Contact Form",
      siteName: "Tribal Reg",
      name: data?.name,
      phone: data?.phone,
      text: data?.text,
      token: "tribalreg"
      // token: "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"
    };
    console.log("form data: ", payload);
    try {
      const response = await api?.post("/send-email", payload);
      if (response) {
        toast?.success("Message Sent Successfully");
      } else {
        toast?.error("Message Sending Failed");
      }
    } catch (error) {
      console.log(error);
    }
    reset();
  };

  return (
    <Container>
      <Row className={`py-5 gy-3 ${classes.rowClass}`}>
        <Col lg={8} md={6} sm={12}>
          <p className={`f-30 fw-400`}>Get In Touch</p>
          <p className={`mt-2 f-45 fw-600 ${classes.heading}`}>Have Ideas or <span className='highlightedText'>Questions?</span> <br />
            <span className='highlightedText'>Let’s</span> Talk!</p>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-3'>
            <Row className={`gy-5`}>
              <Col md={12}>
                <input
                  type="text"
                  placeholder="Name*"
                  className={classes.inputClass}
                  {...register("name", {
                    required: "Name is required",
                    maxLength: {
                      value: 20,
                      message: "Name should not exceed 20 characters",
                    },
                    pattern: {
                      value: /^[A-Za-z\s]+$/,
                      message: "Name must contain only alphabets",
                    },
                  })}
                />
                {errors?.name && <span style={{ color: "red" }}>{errors?.name?.message}</span>}
              </Col>
              <Col md={6} sm={6}>
                <input
                  type="email"
                  placeholder="Email*"
                  className={classes.inputClass}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Enter a valid email address",
                    },
                  })}
                />
                {errors?.email && <span style={{ color: "red" }}>{errors?.email?.message}</span>}
              </Col>
              <Col md={6} sm={6}>
                <input
                  type="number"
                  placeholder="Phone Number"
                  className={classes.inputClass}
                  {...register("phone")}
                />
              </Col>
              <Col md={12}>
                <input
                  type="text"
                  placeholder="Message*"
                  className={classes.inputClass}
                  {...register("text", {
                    required: "Message is required",
                    minLength: {
                      value: 10,
                      message: "Message must be at least 10 characters long",
                    },
                    maxLength: {
                      value: 200,
                      message: "Message cannot exceed 200 characters",
                    },
                  })}
                />
                {errors?.text && <span style={{ color: "red" }}>{errors?.text?.message}</span>}
              </Col>
              <Col md={4}>
                <Button label={"Submit"} type={"submit"} variant={"primary"} />
              </Col>
            </Row>
          </form>

        </Col>
        <Col lg={4} md={6} sm={12} className={classes.infoClass}>
          <InfoBox />
        </Col>
      </Row>
    </Container>
  )
}
