import React from 'react';
import classes from "./ComprehensiveSupport.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import Image from "../../../assets/images/comprehensive-support.jpg";
import { ComprehensiveData, ComprehensiveData2 } from '../../../data/app-data';
import { BsDot } from "react-icons/bs";

export default function ComprehensiveSupport() {
  return (
    <Container>
      <Row className={`gy-3 ${classes.rowClass}`}>
        <Col lg={7} md={12}>
          <p className="f-45 fw-600"><span className="highlightedText">Comprehensive</span> Support for Tribal Lending & <span className="highlightedText">E-Commerce</span> Boards</p>

          <p className="f-16 fw-400 mt-3">Tribal Reg. can perform a number of functions for the tribal lending and ecommerce  board that regulates its business. This would include:</p>

          <ul className={`${classes.pointsDiv} mt-4`}>
            {ComprehensiveData?.map((e, i)=>{
              return(
                <li key={i}>{e?.point}</li>
              )
            })}
          </ul>

            <ul className='mt-3 p-0'>
              {ComprehensiveData2?.map((e, i)=>{
                return(
                  <li className={`f-15 fw-300 ${classes.list} ${classes.hyphenList}`} key={i}>{`- ${e?.point}`}</li>
                )
              })}
            </ul>
          
        </Col>
        <Col lg={5} md={12}>
          <div className={classes.imageDiv}><img src={Image} alt="img" className={classes.image} /></div>
        </Col>
      </Row>
    </Container>
  )
}
