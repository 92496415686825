import React from 'react';
import classes from "./BannerSection.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import { IoSearchOutline } from "react-icons/io5";
import Button from '../../components/Button';

export default function BannerSection() {
  return (
    <Container>
      <Row>
        <Header />
      </Row>
      <Row className=' py-5'>
        <Col lg={7} md={12}>
          <p className={`f-57 fw-600 ${classes.heading}`}><span className={"highlightedText"}>Empowering</span> Tribal Businesses with Expert Regulatory <span className={"highlightedText"}>Solutions</span></p>

          <p className="f-18 fw-300 my-3">At Tribal Reg, we provide tailored regulatory services for tribally owned lending and e-commerce businesses. Leverage our nationwide tribal network and expertise to ensure compliance and drive growth with flexible, scalable support.</p>

          <div className={classes.inputDiv}>
            <div className={classes.inputBox}>
              <IoSearchOutline color='var(--orange-color)' size={20} />
              <input disabled type="text" placeholder='What service are you looking for?' className={classes.inputClass} />
            </div>
            <Button label={"Search"} variant={"primary"} className={classes.btnClass} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}
