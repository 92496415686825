import React from 'react';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-modern-drawer/dist/index.css'
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";

import HomePage from './pages/HomePage';

export default function App() {
  return (
    <HomePage/>
  )
}
